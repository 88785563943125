<template>
  <div
    class="timetable"
    style="padding: 5px;"
  >
    <b-button
      v-ripple.400="'rgba(225,225,225,0.15)'"
      v-b-tooltip="'Click to download as PDF'"
      :variant="'flat-secondary'"
      @click="handleClick"
    >
      <b-overlay
        :show="item.loading"
        variant="transparent"
        spinner-small
        rounded="sm"
        :opacity="0.2"
      >
        <div class="d-flex flex-column">
          <font-awesome-icon
            icon="calendar"
            size="2x"
            :style="{ color: '#fa2' }"
          />
          <strong style="margin-top: 5px;width: 150px;">{{ item.title }}</strong>
        </div>
      </b-overlay>
    </b-button>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'

const { skin } = useAppConfig()
export default {
  name: 'TimeTable',
  components: {
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', this.item)
    },
  },
}
</script>
