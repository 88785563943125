<template>
  <!--    TimeTable Form-->
  <validation-observer
    ref="timeTableForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateTimeTableMode?'Update':'Create'} Time Table`"
      :show="timeTableModalOpened"
      :show-overlay="deletingTimeTable"
      size="lg"
      @onClose="closeTimeTableModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedTimeTableId"
            placeholder="All Time Tables"
            :options="timeTables"
            label-name="title"
            value-name="id"
            @input="populateTimeTableForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteTimeTable()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetTimeTableForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <div class="row">
        <div class="col-md-4">
          <lenon-select
            v-model="timeTable.class_id"
            name="class"
            placeholder="Select Class"
            rules="required"
            label="Class"
            label-name="name"
            value-name="id"
            :options="classes"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="timeTable.title"
            name="title"
            placeholder="Enter timetable title"
            rules="required"
            label="Title"
          />
        </div>
        <div class="col-md-4">
          <lenon-input
            v-model="timeTable.notification_period"
            name="notification_period"
            placeholder="Enter Time in Minutes"
            rules="required|max_value:20"
            type="number"
            label="Notify Teachers"
          />
        </div>
      </div>
      <div
        v-if="timeTable.days.length"
        class="row"
      >
        <div
          v-for="(day,i) in timeTable.days"
          :key="i"
          class="col-md-12"
        >
          <div class="row mt-1">
            <div class="col-12 text-bold">
              <span>DAY {{ i+1 }}</span> <lenon-button
                icon-only
                icon="XIcon"
                variant="flat-danger"
                tool-tip-text="Remove day"
                @onClick="removeDay(i)"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 mb-2"
              style="border-bottom: 1px dashed gray;border-top: 1px dashed gray;"
            >
              <div class="row mt-1">
                <div class="col-md-3">
                  <lenon-select
                    v-model="day.name"
                    :options="days"
                    rules="required"
                    label="Select Day"
                  />
                </div>
              </div>
              <div
                v-if="day.periods.length"
                class="row"
              >
                <div
                  v-for="(period,idx) in day.periods"
                  :key="idx"
                  class="col-12"
                >
                  <div class="row">
                    <div
                      class="col-12 text-bold"
                    >
                      <span>{{ period.is_break?'BREAK':'PERIOD' }} {{ getCount(period.is_break,i,idx) }}</span><lenon-button
                        icon-only
                        icon="XIcon"
                        variant="flat-danger"
                        :tool-tip-text="period.is_break?'Remove break':'Remove period'"
                        @onClick="removePeriod(i,idx)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="period.is_break"
                    class="row"
                  >
                    <div class="col-md-3">
                      <lenon-date-picker
                        v-model="period.start"
                        label="Start Time"
                        rules="required"
                        enable-time
                        hide-calendar
                      />
                    </div>
                    <div class="col-md-3">
                      <lenon-date-picker
                        v-model="period.end"
                        label="End Time"
                        rules="required"
                        enable-time
                        hide-calendar
                      />
                    </div>
                    <div class="col-md-6">
                      <lenon-input
                        v-model="period.title"
                        label="Title"
                        placeholder="Enter some title"
                        rules="required"
                      />
                    </div>
                  </div>
                  <div
                    v-else
                    class="row"
                  >
                    <div class="col-md-3">
                      <lenon-date-picker
                        v-model="period.start"
                        label="Start Time"
                        rules="required"
                        enable-time
                        hide-calendar
                      />
                    </div>
                    <div class="col-md-3">
                      <lenon-date-picker
                        v-model="period.end"
                        label="End Time"
                        rules="required"
                        enable-time
                        hide-calendar
                      />
                    </div>
                    <div class="col-md-3">
                      <lenon-select
                        v-model="period.subject"
                        :options="subjects"
                        label-name="name"
                        value-name="id"
                        label="Subject"
                        placeholder="Select Subject"
                        rules="required"
                      />
                    </div>
                    <div class="col-md-3">
                      <lenon-select
                        v-model="period.teacher"
                        :options="teachers"
                        label="Teacher"
                        label-name="fullName"
                        value-name="id"
                        placeholder="Select Teacher"
                        rules="required"
                      />
                    </div>
                  </div>

                </div>
              </div>
              <div class="row float-right mb-1">
                <div class="col-12">
                  <lenon-dropdown
                    icon="PlusIcon"
                    left
                  >
                    <b-dropdown-item @click="addPeriods(i)">
                      Add Period
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="addPeriods(i,true)">
                      Add Break
                    </b-dropdown-item>
                  </lenon-dropdown>
                  <!--              <lenon-button-->
                  <!--                icon-only-->
                  <!--                icon="PlusIcon"-->
                  <!--                tool-tip-text="Add Period or Break"-->
                  <!--                @onClick="addPeriods(i)"-->
                  <!--              />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row float-right mt-3">
        <div class="col-12">
          <lenon-button
            icon="PlusIcon"
            label="Add a Day"
            variant="outline-primary"
            @onClick="addDay()"
          />
        </div>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeTimeTableModal()"
          />
          <lenon-button
            :label="updateTimeTableMode?'Update':'Create'"
            :disabled="invalid"
            :loading="timeTableLoading"
            loading-text="Loading..."
            @onClick="updateTimeTableMode?updateTimeTable():createTimeTable()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'

export default {
  name: 'TimeTableSetup',
  components: {
    LenonDatePicker,
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      timeTableModalOpened: false,
      updateTimeTableMode: false,
      timeTableLoading: false,
      deletingTimeTable: false,
      selectedTimeTableId: null,
      timeTable: {
        title: null,
        class_id: null,
        notification_period: null,
        days: [

        ],
      },
      days: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    }
  },
  computed: {
    selectedTimeTable() {
      return this.timeTables.find(ter => ter.id === this.selectedTimeTableId)
    },
    timeTables() {
      return this.$store.getters['timetable/timeTables']
    },
    classes() {
      return this.$store.getters['termsClasses/termClasses']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    teachers() {
      return this.$store.getters['termsClasses/teachers']
    },
  },
  watch: {
    modalOpened(opened) {
      this.timeTableModalOpened = opened
    },
  },
  methods: {
    removePeriod(dayIdx, periodIdx) {
      this.timeTable.days[dayIdx].periods.splice(periodIdx, 1)
    },
    removeDay(dayIdx) {
      this.timeTable.days.splice(dayIdx, 1)
    },
    getCount(type, dayIdx, periodIdx) {
      let count = 0
      const { periods } = this.timeTable.days[dayIdx]
      for (let i = 0; i < periods.length; i++) {
        if (periods[i].is_break === type) {
          count++
        }
        if (i === periodIdx) {
          break
        }
      }
      return count
    },
    addDay() {
      let day = { name: null, periods: [] }

      // if there is already a day, use that as starting point for next day
      if (this.timeTable.days.length > 0) {
        day = {
          ...this
            .timeTable.days[0],
          periods: this.timeTable.days[0].periods.map(p => ({ ...p })),
        }
        day.name = null
        for (let i = 0; i < day.periods.length; i++) {
          if (!day.periods[i].is_break) {
            day.periods[i].subject = null
            day.periods[i].teacher = null
          }
        }
      }
      this.timeTable.days.push(day)
    },
    addPeriods(idx, isBreak = false) {
      if (isBreak) {
        this.timeTable.days[idx].periods.push({
          start: null, end: null, title: null, is_break: isBreak,
        })
      } else {
        this.timeTable.days[idx].periods.push({
          start: null, end: null, subject: null, teacher: null, is_break: isBreak,
        })
      }
    },
    // timeTable
    resetTimeTableForm() {
      this.timeTable = {
        title: null,
        class_id: null,
        notification_period: null,
        days: [

        ],
      }
      this.selectedTimeTableId = null
      this.updateTimeTableMode = false
      this.$refs.timeTableForm.reset()
    },
    populateTimeTableForm(timeTable) {
      if (this.selectedTimeTable) {
        this.updateTimeTableMode = true
        this.timeTable = { ...this.selectedTimeTable }
      } else {
        this.resetTimeTableForm()
      }
    },
    closeTimeTableModal() {
      this.timeTableModalOpened = false
      this.$emit('modalClosed')
    },
    updateTimeTable() {
      this.error = {}
      if (!this.selectedTimeTableId) {
        return
      }
      this.timeTableLoading = true
      this.$store.dispatch('timetable/updateTimeTable', this.timeTable).then(res => {
        this.showSuccess('Updated timetable successfully')
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update timetable')
      }).finally(() => {
        this.timeTableLoading = false
      })
    },
    createTimeTable() {
      this.error = {}
      this.timeTableLoading = true
      this.$store.dispatch('timetable/createTimeTable', this.timeTable).then(res => {
        this.showSuccess('Created time table successfully')
        this.resetTimeTableForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to create timetable')
      }).finally(() => {
        this.timeTableLoading = false
      })
    },
    deleteTimeTable() {
      if (!this.selectedTimeTableId) {
        this.showInfo('Please select a timetable')
        return
      }
      this.deletingTimeTable = true
      this.$store.dispatch('timetable/deleteTimeTable', this.selectedTimeTableId).then(() => {
        this.showSuccess('Deleted Successfully')
        this.selectedTimeTableId = null
        this.resetTimeTableForm()
      }).catch(() => {
        this.showError('Failed to delete timetable')
      }).finally(() => {
        this.deletingTimeTable = false
      })
    },

  },
}
</script>
