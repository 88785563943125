<template>
  <div class="academic-years">
    <lenon-list-page
      title="Time Tables"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-profile-photo="true"
      search-placeholder="Search"
      :hide-table="true"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Time Table"
            tool-tip-text="Manage school time tables"
            @onClick="showTimeTableModal(true)"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-button
            v-b-toggle.report
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="col-12">
          <b-collapse
            id="report"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6 float-right mb-1">
                <lenon-button
                  variant="outline-primary"
                  icon="SettingsIcon"
                  label="Time Table"
                  tool-tip-text="Manage school time tables"
                  @onClick="showTimeTableModal(true)"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="table-content">
        <div class="d-flex flex-row flex-wrap">
          <time-table
            v-for="(item,i) in timeTables"
            :key="i"
            :item="item"
            @click="downloadTimeTable"
          />
        </div>
      </template>
    </lenon-list-page>
    <time-table-setup
      :modal-opened="timeTableModalOpened"
      @modalClosed="closeModal('timeTable')"
    />
  </div>
</template>

<script>
import {
  BCollapse, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import TimeTableSetup from '@/views/documents/timetable/TimeTableSetup'
import TimeTable from '@/views/documents/timetable/TimeTable'

export default {
  name: 'Results',
  components: {
    TimeTable,
    TimeTableSetup,
    LenonSelect,
    LenonListPage,
    LenonButton,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      selectedClassesForModal: [],
      selectedAcademicYearId: null,
      selectedTermId: null,
      timeTableModalOpened: false,
    }
  },
  computed: {
    monitorSettings() {
      return +this.selectedAcademicYearId
          * +this.selectedTermId
    },
    previousSetting() {
      return this.$store.getters['timetable/settings']
    },
    timeTables() {
      return this.$store.getters['timetable/timeTables']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    monitorSettings() {
      if (this.selectedTermId && this.selectedAcademicYearId) {
        this.$store.commit('timetable/setSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
        })
        this.fetchTimeTables()
      }
    },
  },
  mounted() {
    this.setSettings()
  },
  methods: {
    downloadTimeTable(item) {
      this.tableLoading = true
      this.$store.dispatch('timetable/generateDocument', item.id).then(path => {
        this.downloadFile(path, item.title)
      }).finally(() => {
        this.tableLoading = false
      })
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'timeTable':
          this.timeTableModalOpened = false
          break
      }
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    showTimeTableModal() {
      this.timeTableModalOpened = true
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
    },
    fetchTimeTables() {
      this.tableLoading = true
      this.$store.dispatch('timetable/fetchTimeTables', { term_id: this.selectedTermId, ay_id: this.selectedAcademicYearId }).finally(()=>{
        this.tableLoading = false
      })
    },
  },
}
</script>
